const updateURL = (name, value) => {
    //Returns baseURL + "&" + routeParam
    const urlData = new URL(window.location); 
    if(value !== ""){
      let data =[]
      if (urlData.hash.includes("&")) {
        data = urlData.hash?.substring(1)?.split("&");               
      }
      const index = data?.findIndex(ele=> ele.includes(name));
      if(index > -1){
        data[index] = name + "=" + value;
      }else{
        data.push(name + "=" + value);
      }
      const baseUrl = urlData.hash.split('&')[0].replace('#','');
      if(data.length > 1){
        data.shift()
      }
      let routeParams =  data.join('&');
      return (baseUrl+'&'+routeParams)       
    }
  }
  
  const clearURL = (key) => {
    if(key !== ''){
        const urlData = new URL(window.location); 
        let data = []
        if(urlData.hash.includes("&")){
          data = urlData.hash?.substring(1)?.split("&");
        }
        const index = data?.findIndex(ele=> ele.includes(key));
        if(index > -1){
          data.splice(index,1);
        }
        const baseUrl = urlData.hash.split('&')[0].replace('#','');
        if(data.length >= 1)
          data.shift()
        let routeParams = data.join('&');        
        if(routeParams !== "") return baseUrl+'&'+routeParams
        return baseUrl
    }
  }
  
  export const updateURLStatus = (name, value) =>{ 
    // function that is to be called To change URL params
    let url = "";
    if(value !==""){
      url =  updateURL(name, value)
    }
    else{
      url = clearURL(name)
    }
    return url;
  }
