/* eslint-disable no-useless-escape */
import { createStore } from "redux";

const initialState = {
  notifySpinner : true,
  notificationShow: "responsive",
  unreadNotifications : [],
  sidebarShow: true,
  asideShow: false,
  darkMode: false,
  errorCode:"disabled",
  log_level:"error",
  time_format:"12 Hours AM/PM",
  date_format:"MM/DD/YYYY",
  time_zone:"",
  userLoginDetails: {},
  userToken: "",
  notifications: [],
  notificationCount: 0,
  wtNotifications:[],
  buNotifications:[],
  WareHouseIDs: [],
  lastOTSJobObject : {},
  otsStyleIds : [],
  wtJobId:"",
  buJobId:"",
  importtransferMessages:[],
  isreport: false,
  accountName: "",
  showHeader: true,
  currentUrls: [],
  warehousedetails: {},
  isRouteChange : false,
  isFormChange:false,
  isWMS: false,
  isToast: false,
  isLoading : false,
  isPickTicketDetails:false,
  applicationFlags:{
    featureFlags: [],
    menuFlags: []
  },
  isApplicationFlagsLoaded: false,
  mondayComSettings:{},
  receivingDetails: {
    sizes: [],
    upcIdList: {},
    wipDetails: {},
    wipId: "",
    status: {},
  },
  reminders:[],
  syncData:[],
  signalRId:"",
  AccessPermission:null,
  navload : true,
  sidebarRefresh : false,
  sidebarMaximized: true,
  pickticketRecursion : false,
  BackToPickTicket:true,
  favoritesItems : [],
  pickingConfig:{
  },
  refreshTable:false,
  uiRequestId: "",
  publishLinkLocal: "",
  navigateToDetail:"true",
  statusLinks:{ validaton: true, process: true },
  fieldvalidations : [
    {
      name : "UPCNumber",
      regexPattern : "^[0-9]{0,14}$",
      maxSize: 15
    },
    {
      name : "StyleName",
      regexPattern : "^[a-zA-Z0-9-.]{0,15}$",
      maxSize: 16
    },
    {
      name : "BINLocation",
      regexPattern : "^(?!.*[- _.]{2})[A-Za-z0-9- _\\.]{1,100}$",
      maxSize: 101
    },
    {
      name : "Digits",
      regexPattern : "^[0-9]{0,6}$",
      maxSize: 7
    },
    {
      name : "Warehouse",
      regexPattern : "^[A-Za-z0-9!@#$%^&*`~()_+\\-=[\\]{};':\"\\\\|,.<>/ ?]{0,60}$",
      maxSize: 61
    },
    {
      name : "AdjustmentNotes",
      regexPattern : "^[A-Za-z0-9!@#$%^&*`~()_+\\-=[\\]{};':\"\\\\|,.<>/ ?]+$",
      maxSize: 201
    },
    {
      name : "Quantity",
      regexPattern : "^[0-9]{0,9}$",
      maxSize: 10
    },
    {
      name : "AdjustmentQuantity",
      regexPattern : "^(-?[0-9]{0,9})$",
      maxSize: 10
    }
  ],
  cosmosConfigs:{
  }
};

const changeState = (state = initialState,{ type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "update":
      return { ...state, ...rest };
    case "delete":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
