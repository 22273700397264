import { CButton } from '@coreui/react';
import React from 'react';
import { useHistory, useLocation } from "react-router-dom";

const Action = ({ item, clientAccount, modalSchedulerBody, job }) => {
    const history = useHistory();
    const location = useLocation();

    const handleEditClick = () => {
        history.push({
            pathname: location?.pathname === "/reports/aquascheduler/joblist" ? "/reports/aquascheduler/joblist/Edit" : `/aquascheduler/editjob`,
            search: `?loginprefix=${clientAccount}`,
            source: location?.pathname,
            settings: modalSchedulerBody,
            origin: item,
            modalData: item,
            jobList: job,
        });
        localStorage.setItem("payloadSetting", JSON.stringify(item));
    };

    const handleHistoryClick = () => {
        history.push({
            pathname: location?.pathname === "/reports/aquascheduler/joblist" ? "/reports/aquascheduler/joblist/history" : `/aquascheduler/Jobhistory`,
            search: `?loginprefix=${clientAccount}&jobId = ${item?.pubLink?.split("/")[6]}`,
            jobList: job,
            jobId: item?.pubLink?.split("/")[6],
        });
        localStorage.setItem("jobId", item?.pubLink?.split("/")[6]);
    };

    return (
        <td>
            <div className="d-flex">
                <CButton color="primary" className="mr-1" onClick={handleEditClick}>
                    <i className="fa-solid fa-pen-to-square" />
                </CButton>
                <CButton color="warning" className="text-white" onClick={handleHistoryClick}>
                    <i className="fa-solid fa-clock-rotate-left" />
                </CButton>
            </div>
        </td>
    );
};

export default Action;
