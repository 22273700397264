/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import Page403 from './views/pages/page403/Page403';
import LoggerProvider from './views/WMS/Common_Helper/services/uiLoginServices';
import { getIpAddress } from './views/WMS/Common_Helper/services/getIpAddress';
import { AquaScheduler, JobList, EditDetails, JobHistory } from "./views/ExternalModules";
import { Provider } from 'react-redux';
import store from './store';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const SchedulerEditor = React.lazy(() => import('./views/reportMail/reportHelper/scheduleEditor'))
const ERPCallback = React.lazy(() => import('./views/pages/aims-erp-login/ERPCallback'));
const UnderMaintenance = React.lazy(() => import('./views/pages/aims-erp-login/UnderMaintenance'));

function App() {

  useEffect(() => {
    getIpAddress();
  }, []);


  return (
    <HashRouter>
      <Provider store={store}>
        <LoggerProvider>
          <React.Suspense fallback={loading}>
            <Switch>
              {/* <Route exact path="/" name="Login Page" render={props => <AIMS360ERP {...props} />} />
            <Route exact path="/login" name="Login Page" render={props => <AIMS360ERP {...props} />} /> */}
              <Route exact path="/undermaintenance" name="Client Page" render={props => <UnderMaintenance {...props} />} />
              {/* This Routes for Separate applications of Aqua Scheduler */}
              <Route exact path="/aquascheduler/create" name="Aqua Scheduler" render={props => <AquaScheduler {...props} />} />
              <Route exact path="/aquascheduler/joblist" name="Aqua JobList" render={props => <JobList {...props} />} />
              <Route exact path="/aquascheduler/editjob" name="Edit Details" render={props => <EditDetails {...props} />} />
              <Route exact path="/aquascheduler/Jobhistory" name="Aqua Job History" render={props => <JobHistory {...props} />} />
              <Route exact path="/aquascheduler/SchedulerEditor" name="Scheduler Editor" render={props => <SchedulerEditor {...props} />} />
              {/* This Routes for Separate applications of Aqua Scheduler */}
              <Route exact path="/clientcode" name="client page" render={props => <ERPCallback {...props} />} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              <Route exact path="/403" name="Page 403" render={props => <Page403 {...props} />} />
              <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="*" name="Page 404" render={props => <Page404 {...props} />} />

            </Switch>
          </React.Suspense>
        </LoggerProvider>
      </Provider>
    </HashRouter>
  );
}


export default App;
