import { useLocation, useHistory } from "react-router-dom";
import { updateURLStatus } from "../urlParams";
export default function useURL() {
  const history = useHistory();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const getURLData = (name) => {
    return queryParameters?.get(name);
  };
  const setURLData = (name, value) => {
    return history.push(updateURLStatus(name, value));
  };
  return { getURLData, setURLData };
}
