import axios from 'axios';
import { AuthenticateURL, SetHeaders, getCustomFields, getRMAReasonCode, warehouseByRole } from '../Common_Helper/API_Endpoints'
import { v4 as uuidv4 } from 'uuid';
import { getUserDetailsFromLocalStorage, getZoneFromLocalStorage } from "../../../Services/LocalStorageService";
import _ from 'lodash';
import { GetRequest } from './services/Odata.orchestration.Service';
import createInterceptorInstance from '../../../Services/Interceptor';
import { AddSpinner, RemoveSpinner } from '../spinner';

let addList = [];
function getService(url, logger, setLogLevel) {
  const guid = uuidv4();
  const body = {
    "RequestUri": url,
    "Method": "get"
  }
  const URL = createInterceptorInstance(AuthenticateURL());
  return URL.post('', body, SetHeaders()).then(res => {
    logger('debug', guid, res, "AIMS360RunwayWMS", url, "GET"); // log request and response data
    return res.data;
  })
    .catch(error => {
      logger('error', guid, error, "AIMS360RunwayWMS", url, "GET"); // log error
      throw error;
    });
}
async function postService(url, payload, logger) {
  const guid = uuidv4();
  const body = {
    "RequestUri": url,
    "Method": "post",
    "RequestBody": JSON.stringify(payload)
  };

  try {
    const URL = createInterceptorInstance(AuthenticateURL());
    const response = await URL.post('', body, SetHeaders());
    logger('debug', guid, response, "AIMS360RunwayWMS", url, "POST", payload); // log request and response data
    return response.data;
  } catch (error) {
    logger('error', guid, error, "AIMS360RunwayWMS", url, "POST", payload); // log error
    throw error;
  }
}
function UpdateService(url, payload) {
  const body = {
    "RequestUri": url,
    "Method": "put",
    "RequestBody": JSON.stringify(payload)
  }
  const URL = createInterceptorInstance(AuthenticateURL());
  return URL.post('', body, SetHeaders()).then((result) => result.data)
}

const CosmosDbRefresherPostRequest =(url, body,logger)=>{
  const guid = uuidv4();
  const URL = createInterceptorInstance(url);
  return URL.post('', body, SetHeaders())?.then(res => {
      logger('debug', guid,res, "", url, "PUT",body); // log request and response data
      return res;
  })
  .catch(error => {
      logger('error', guid,error, "", url, "PUT",body); // log error
      throw error;
  });
}

function customPostService(url, payload, logger, importType = null) {
  const guid = uuidv4();
  const URL = createInterceptorInstance(url);
  return URL.post('', payload, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      // "Authorization": "Bearer "
      "import-Type": importType
    },
  })
    .then(res => {
      logger('debug', guid, res, "AIMS360RunwayWMS", url, "POST", payload); // log request and response data
      return res.data;
    })
    .catch(error => {
      logger('error', guid, error, "AIMS360RunwayWMS", url, "POST", payload); // log error
      throw error;
    });
}

async function LogsPostService(url, payload) {
  const token = localStorage.getItem('ApplicationToken');
  return await axios.post(url, payload, {
    // withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + token
    }
  }).then((result) => result.data)
}
function customGetService(url, logger) {
  const guid = uuidv4();
  const body = {
    "RequestUri": url,
    "Method": "get"
  }
  return axios.post(url, body, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      // "Authorization": "Bearer " + token
    }
  }, guid).then(res => {
    logger('debug', guid, res, "AIMS360RunwayWMS", url, "GET"); // log request and response data
    return res.data;
  })
    .catch(error => {
      logger('error', guid, error, "AIMS360RunwayWMS", url, "GET"); // log error
      throw error;
    });
}

function updateTicket(url, payload, logger) {
  const guid = uuidv4();
  const URL = createInterceptorInstance(url);
  return URL.put('', JSON.stringify(payload), SetHeaders(), guid).then(res => {
    logger('debug', guid, res, "AIMS360RunwayWMS", url, "PUT", payload); // log request and response data
    return res.data;
  })
    .catch(error => {
      logger('error', guid, error, "AIMS360RunwayWMS", url, "PUT", payload); // log error
      throw error;
    });
}

function getCall(url, logger, guid) {
  const URL = createInterceptorInstance(url);
  let res = URL.get('', SetHeaders(), guid);
  logger('debug', guid, res, "AIMS360RunwayWMS", url, "GET"); // log request and response data
  return res;
}

function postMultipleSizesAdj(url, payload, logger) {
  const zone = getZoneFromLocalStorage();

  let baseUrl = "";
  if (zone?.toLowerCase() === 'east') {
    baseUrl = `${process.env.REACT_APP_WMS_ADJUSTMENT_EAST_FUNCTION_URL}`;
  } else {
    baseUrl = `${process.env.REACT_APP_WMS_ADJUSTMENT_WEST_FUNCTION_URL}`;
  }

  const guid = uuidv4();
  const body = {
    "RequestUri": url,
    "Method": "post",
    "RequestBody": JSON.stringify(payload)
  }
  const URL = createInterceptorInstance(baseUrl);
  return URL.post('', body, SetHeaders(), guid).then(res => {
    logger('debug', guid, res, "AIMS360RunwayWMS", url, "POST", payload); // log request and response data
    return res.data;
  })
    .catch(error => {
      logger('error', guid, error, "AIMS360RunwayWMS", url, "POST", payload); // log error
      throw error;
    });
}

function fetchUserSettingsDetails(updateData, logger) {
  const guid = uuidv4();
  const dispatch = updateData
  const zone = getZoneFromLocalStorage();
  let URL = `${process.env.REACT_APP_SETTINGS_WEST}/UserSettings`;
  if (zone?.toLowerCase() === "east") {
    URL = `${process.env.REACT_APP_SETTINGS_EAST}/UserSettings`;
  }
  const data = JSON.stringify({
    "requestType": "getUserSettings",
    "moduleType": "userData"
  });

  axios.post(URL, data, {
    withCredentials: true,
    headers: {
      //"Authorization": `Bearer ${getTokenFromLocalStorage()}`
    }
  })
    .then((res) => {
      if (res.status === 200) {
        //setting theme value in store
        if (res.data.payload.theme === "dark")
          dispatch({ type: 'set', darkMode: true });
        else
          dispatch({ type: 'set', darkMode: false });
        // setting log-level value in store
        let DebugLevel = "error";
        DebugLevel = res?.data?.payload?.log_level;
        if (!DebugLevel) {
          DebugLevel = "error";
        }
        dispatch({ type: 'set', log_level: DebugLevel.toLowerCase() === "debug" ? "debug" : "error" });
        // setting DateFormat value in store
        handleDateFormat(res, dispatch);
        //setting TimeFormat value in store
        handleTimeFormat(res, dispatch);
        //setting time zone value in store
        handleTimezone(res, dispatch);
        //setting footer pin status in store
        handleFooterPin(res, dispatch);
        //setting  ErrorCode  value in store
        handleErrorCode(res,dispatch)
        localStorage.setItem("userSettingsData", JSON.stringify(res.data.payload));

        //setting default time zone value in local storage
        handleDefaultTimeZone(res);

      }
      logger('debug', guid, res, "AIMS360RunwayWMS", URL, "POST", data);
    }).catch((e) => {
      console.log(e)
      logger('error', guid, e, "AIMS360RunwayWMS", URL, "POST", data);
    });

}
const handleDateFormat = (res, dispatch) => {
  let DateFormat = "MM/DD/YYYY";
  DateFormat = res?.data?.payload?.date_format;
  if (!DateFormat) {
    DateFormat = "MM/DD/YYYY";
  }
  dispatch({ type: 'set', date_format: DateFormat });
}
const handleTimeFormat = (res, dispatch) => {
  let TimeFormat = "12 Hours AM/PM";
  TimeFormat = res?.data?.payload?.time_format;
  if (!TimeFormat) {
    TimeFormat = "12 Hours AM/PM";
  }
  dispatch({ type: 'set', time_format: TimeFormat });
}
const handleFooterPin = (res, dispatch) =>{
  if (res?.data?.payload?.pinStatus === "active")
  dispatch({ type: 'set', isToast: true });
else
  dispatch({ type: 'set', isToast: false });
}
const handleTimezone = (res, dispatch) => {
  let TimeZone = "";
  TimeZone = res?.data?.payload?.time_zone;
  if (!TimeZone) {
    TimeZone = localStorage.getItem("defaultTimeZone");
  }
  dispatch({ type: 'set', time_zone: TimeZone });
}
const handleErrorCode=(res, dispatch)=>{
  let displayErrorcode = "disabled";
    displayErrorcode = res?.data?.payload?.error_code;
    if (displayErrorcode === undefined || displayErrorcode === null || displayErrorcode === "") {
      displayErrorcode = "disabled";
    }
    dispatch({ type: 'set', errorCode: displayErrorcode?.toLowerCase() === "enabled" ? "enabled" : "disabled" });
}
const handleDefaultTimeZone = (res) => {
  let DefaultTimeZone = '';
  let clientTimeZone = '';
  DefaultTimeZone = res?.data?.payload?.MomentTimezone;
  clientTimeZone = res?.data?.payload?.TimeZone;
  if (!clientTimeZone) {
    clientTimeZone = "Pacific Standard Time";
  }
  localStorage.setItem("clientTimeZone", clientTimeZone);
  if (!DefaultTimeZone) {
    DefaultTimeZone = 'PST8PDT';
  }
  localStorage.setItem('defaultTimeZone', DefaultTimeZone);
}

function fetchWarehouseListByRoleId(roleid,logger){
        let url=warehouseByRole.replace('{roleid}', roleid)
        GetRequest(url,logger).then((res)=>{
          if (res?.status === 200) {
            let wareHouses = res?.data?.value;
            wareHouses = _.filter(wareHouses, { status: "Active" });
            const sortedWarehouse = _.sortBy(wareHouses, o => o?.label)
            localStorage.setItem("warehouseList", JSON.stringify(sortedWarehouse));
        } else {
            
            console.log('unable to fetch warehouse')
        }

    }).catch((err) => {
        console.log(err);
    })
}

function fetchReasonCode(logger) {
  let url = getRMAReasonCode
  GetRequest(url, logger).then((res) => {

    let reasonCodelist = res?.data?.items;
    if (reasonCodelist.length > 0) {
      localStorage.setItem("ReasonCodeList", JSON.stringify(reasonCodelist))
    }

  }).catch((err) => {
    console.log(err)
  })

}

function fetchCustomFields(logger) {
  let url = getCustomFields;
  const guid = uuidv4();
  getCall(url, logger, guid)
    .then(async (res) => {
      if (res?.status === 200) {
        addList = res?.data?.value;
        if (res?.data?.["@odata.nextLink"] && res?.data?.["@odata.count"] >= 250) {
          handleNextLink(res?.data?.["@odata.nextLink"]);
        }
        else {
          localStorage.setItem("CustomFields", JSON.stringify(addList));
        }
      }

      else {
        console.log(res?.message)
      }
    }).catch((err, guid) => {
      console.log(err);
      logger('error', guid, err, "AIMS360RunwayWMS", url, "GET");
    });

  const handleNextLink = (nextLink) => {
    const guid = uuidv4();
    getCall(nextLink, logger, guid).then(async (res) => {
      if (res?.status === 200) {
        let value = res?.data.value;
        addList = [...addList, ...value];
        if (res?.data?.["@odata.nextLink"] && res?.data?.value?.length !== 0) {
          handleNextLink(res?.data?.["@odata.nextLink"]);
        }
        else {
          localStorage.setItem("CustomFields", JSON.stringify(addList));
        }
      }
      else {
        console.log(res?.message)
      }
    }).catch((err, guid) => {
      console.log(err);
      logger('error', guid, err, "AIMS360RunwayWMS", nextLink, "GET");
    });
  }
}

function fetchfeatureFlags(updatesettingKeyValue) {
  const dispatch = updatesettingKeyValue;
  let userInfo = getUserDetailsFromLocalStorage();
  dispatch({ type: "set", isApplicationFlagsLoaded: false });
  let url = "";
  if (userInfo?.Zone?.toLowerCase() === "east") {

    url = `${process.env.REACT_APP_FEATURE_FLAG_URL_EAST}`
  } else {
    url = `${process.env.REACT_APP_FEATURE_FLAG_URL_WEST}`
  }
  const URL = createInterceptorInstance(url);
  URL.get('', { withCredentials: true, headers: {} }).then((res) => {
    dispatch({ type: "set", isApplicationFlagsLoaded: true });
    const data = res?.data;
    dispatch({ type: "set", applicationFlags: { ...data } });
    dispatch({ type: "set", navload: false });
  }
  ).catch((e) => {
    dispatch({ type: "set", isApplicationFlagsLoaded: true });
    dispatch({ type: "set", applicationFlags: {} });
    console.log(e)
  })

}

const handleReceivingStatusUrl=(receiveType)=>{
  if(receiveType==="Cut"){
    return `wms/receiving/CutTicketReceivingDetails/CutTicketReceivingStatus`
  }
  else if(receiveType==="Dye"){
    return `wms/receiving/GarmentDyeReceivingDetails/GarmentDyeReceivingStatus`
  }
  else if(receiveType==="PO"){
    return `wms/receiving/POReceivingDetails/POReceivingStatus`
  }
  else if(receiveType==="ExtWH"){
    return `wms/receiving/WarehouseTransferReceivingDetails/WarehouseTransferReceivingstatus`
  }
}

const ReceivingJobDetails = (url, logger) => {
  AddSpinner()
  return new Promise((resolve, reject) => {
    GetRequest(url, logger)
      .then((res) => {
        RemoveSpinner()
        if (res?.status === 200) {
          const params = res?.data?.jobDetails?.param;
          const responseParse = JSON.parse(params);
          const receiveType = responseParse?.receivingParam?.receiveType;
          const wipDetails = responseParse?.receivingParam?.wipDetails;
          const keys = Object.keys(wipDetails);
          let foundKey;
          keys?.forEach((item)=>{
          if(item==="cutTicket"){
            foundKey="Cut"
          }
          if(item==="purchaseOrderNumber"){
            foundKey="PO"
          }
          if(item==="garmentDye"){
            foundKey="Dye"
          }
          if(item==="warehouseTransferNumber"){
            foundKey="ExtWH"
          }
          })
        let result = receiveType ? receiveType : foundKey;
        resolve(result);
        } else {
          reject(new Error("Failed to get receiving job details"));
        }
      })
      .catch((err) => {
        reject(err);
        RemoveSpinner()
      });
  });
};


export {
  // fetchCustomErrors,
  fetchReasonCode,
  fetchfeatureFlags,
  fetchCustomFields,
  //fetchWarehouseList,
  fetchUserSettingsDetails,
  getService,
  postService,
  UpdateService,
  customPostService,
  customGetService,
  getCall,
  updateTicket,
  LogsPostService,
  postMultipleSizesAdj,
  fetchWarehouseListByRoleId,
  CosmosDbRefresherPostRequest,
  ReceivingJobDetails,
  handleReceivingStatusUrl
}
