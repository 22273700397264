const MobileContent = () => {
    let contentlist = {
        Adjustment: "Adj",
        Allocated: "Alloc",
        Allocation: "Alloc",
        Available:"Avail", 
        Cancelled: "Cxl",
        CutTicket:"Cut",
        Damaged: "Dmg",
        Date: "Dte",
        Description: 'Desc',
        Details: 'Dets',
        DyeJob:"Dye",
        "Garment Dye":"Dye",
        Inquiry: "Inq",
        inWarehouseDate: "In-WH Dte",
        Location: "Loc",
        Number:"No",
        Ordered: "Ord",
        Operation : "Oper",
        Quantity: "QTY",
        PickTicket: "Pkd",
        Prod: "Prod",
        Production: "Prod",
        ProductionTracking: "Prod Trx",
        PurchaseOrder: "PO",
        Received: "Rec",
        Receiving:"Recv",
        Total:"TTL",
        Transfer: "Xfer",
        Transfered: "Xfer",
        Warehouse:"Whse",
        Category: "Catg",
        Manufacturing: "Mfg",
        Estimated: "Est",
        RequestID: "Req-ID",
        "Transfer QTY": "Xfer QTY",
        "Received QTY": "Rec QTY",
        "Source Warehouse": "Source",
        "Destination Warehouse": "Destination",
        "Created On Date": "Created On",
        "Transfer ID": "Xfer ID",
        movedQuantity: "Stock QTY"
    }
    return contentlist;
}

const MobileMenuLables = () => {
    let contentlist = {
        'AIMS360DASH01': 'Dashboard',
        'AIMS360STYLES01': 'Styles',
        'AIMS360CUST01':'Customers',
        'AIMS360ORDERS01' : 'Orders',
        'AIMS360WMS01': "WMS",
        'AIMS360WMSADJ01': "Adjustment",
        'AIMS360WMST01': "New Transfer",
        'AIMS360WMSWT01': "Transfers List",
        'AIMS360WMSINQ01': 'Inquiry',
        'AIMS360WMSPKT01':"Pick Tickets",
        'AIMS360WMSPJ01': "Production Jobs",
        'AIMS360WMSREC01': "Receiving",
        'AIMS360WMSCTE01': "Cut Ticket Edit",
        'AIMS360WMSGD01':"Garment Dye",
        'AIMS360LR01': "Loop Returns",
        'AIMS360LROS01' : "Order Status",
        'AIMS360RWACC01': "Accounting",
        'AIMS360RWSAGEEP01':"Sage 100 Export",
        'AIMS360MC01': "Monday.com",
        'AIMS360MCSJ01': "Sync Jobs",
        'AIMS360MCSD01': "Sync Data",
        'AIMS360MCSM01': "Sync Manually",
        'AIMS360IM01': "Import",
        'AIMS360IFI01': "File Import",
        'AIMS360IIS01': "Import Status",
        'AIMS360REPORTS01' : "Reports",
        'AIMS360OTSBD01':"OTS By Date",
        'AIM360AQSCH01': "Aqua Scheduler",
        'AIMS360OTSAC01': "Create Job",
        'AIMS360OTSAJ01':"Job List",
        'AIMS360CP01': "Custom Programs",
        'AIMS360CPCREAI': "CREAI",
        'AIMS360CPOTSIR01': "OTSi Report",
        'AIMS360CPPORCT01': "PO Receipts",
        'AIMS360CPCOGSH01': "COGS History",
        'AIMS360CPLOSAN': "LOSAN",
        'AIMS360CPLOSANFTP01': "Style List Upload to FTP",
        'AIMS360CPLAARCT01': "LAA Cat & 812 Ldgr",
        'AIMS360CP812REP01': "812 Replenishment",
        'AIMS360CPLOSANWFTP01': "Wholesale Upload to FTP",
        'AIMS360RWS01': "Setup",
        'AIMS360LRS01': "Loop Return Settings",
        'AIMS360MCS01': "Monday.com Settings",
        'AIMS360RWSAGECONF01': "Sage 100 Configuration",
        'AIMS360RWSS01': "System Settings",
        'AIMS360RWSR01': "User Roles",
        'AIMS360RWSU01': "Users",
        'AIMS360WMSSET01': "WMS Settings",
        'AIMS360RWSW01': "Warehouse Settings",
        'AIMS360RWWIP01':"Work In Process (WIP)",
        'AIMS360RWWIPVPO01':'Vendor POs'
    }
    return contentlist;
}

export { MobileContent, MobileMenuLables }