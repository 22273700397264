const Response =(data)=>{
  const {
    clientId,
    guid,
    account,
    category,
    machineId,
    type,
    url,
    timeZoneStandardName,
    ip,
    res,
  } = data;

    let payload =
       { 
        "clientID": clientId,
        "requestId":guid,
        "customerAccount": account,
        "Environment": process.env.REACT_APP_ENV === "dev" ? "Test" : "Producation",
        "Version": "1.0",
        "Category": category,
        "RequestURL": url,
        "RequestType": type,
        "LogType": "Response",
        "Response":JSON.stringify(res).substring(0, 30000),
        "StatusCode": res?.status ? res?.status : "",
        "StatusMessage": res?.statusmessage ? res?.statusmessage : "",
        "Requestbody":"",
        "ThreadName": "3415",
        "IPAddress": ip,
        "MachineName": ip,
        "Reference1": "",
        "Reference2": "",
        "Reference3": "",
        "Reference4": "",
        "Reference5": "",
        "MachineMacID":machineId,
        "timeZoneStandardName": timeZoneStandardName,
      }
       return payload;
         
 }
 export {Response}