/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { getUserLoginPrefixFromLocalStorage, getZoneFromLocalStorage } from '../../../../Services/LocalStorageService';
import { LogsPostService } from '../API_Base';
import { Exception } from '../LoggingFunctions/Exception';
import { Request } from '../LoggingFunctions/Request';
import { Response } from '../LoggingFunctions/Response';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from "react-router-dom";


export const LoggerContext = createContext();

const LoggerProvider = ({ children }) => {
  const Level = useSelector(state => state.log_level);
  const time= useSelector(state => state.time_format)
  const dateformat=useSelector(state=>state.date_format);
  const timezone=useSelector(state=>state.time_zone);
  const debugEnabled = Level?.toLowerCase() === "debug" ? "debug" : "error"
  const [logLevel, setLogLevel] = useState('error');
  const clientId = localStorage.getItem('clientId');
  const customerAccount = localStorage.getItem('UserAccount');
  const { v4: uuidv4 } = require('uuid');
  const defaultTimeZone = "Pacific Standard Time";
  const flag = 12;
  let ip=localStorage.getItem("IP")
  const [timeZoneStandardName, setTimeZoneStandardName] = useState('');
  const clientAccount = getUserLoginPrefixFromLocalStorage();
  const dispatch = useDispatch();
  const [unAuthorised,setUnAuhtorised] = useState(false)
const history = useHistory();
  const getMachineId = useCallback(() => {
    const uuid = uuidv4();
    return uuid;
  }, []);

  if(flag !== 12){
    console.log(time, dateformat, timezone);
  }
  useEffect(() => {
    const storedTimeZone  = localStorage.getItem("clientTimeZone") || defaultTimeZone;
    setTimeZoneStandardName(storedTimeZone);
  }, [])
useEffect(()=>{
  if(unAuthorised){
    history.push(`/Unauthorised?loginprefix=${clientAccount}`)
  }
},[unAuthorised, clientAccount, history])
  const logger = useCallback((level,guid, data, category, url, type,body) => {
    
    if (level === 'error' && logLevel === 'error' && (debugEnabled?.toLowerCase() === "error" || debugEnabled?.toLowerCase() === "debug")) {
      const obj = {
        clientId: clientId,
        guid: guid,
        account: customerAccount,
        category: category,
        e: data,
        machineId: getMachineId(),
        type: type,
        url: url,
        body: body,
        timeZoneStandardName: timeZoneStandardName,
        ip: ip,
      }; 
      let auth = localStorage.getItem("Unauthorised");
      let permissioncode = localStorage.getItem("PermissionCode")
      if(auth && !permissioncode){
        setUnAuhtorised(true);
      }
      const payload = Exception(obj);
      sendLog(payload, 'exception');
      console.error(data);
    } 
    else if (level === 'debug' && ['error', 'debug'].includes(logLevel) && debugEnabled?.toLowerCase() === "debug") {
      const obj = {
        clientId: clientId,
        guid: guid,
        account: customerAccount,
        category: category,
        res: data,
        machineId: getMachineId(),
        type: type,
        url: url,
        body: body,
        timeZoneStandardName: timeZoneStandardName,
        ip: ip,
      };
      sendLog(Request(obj), 'request');
      sendLog(Response(obj), 'response');
      console.debug(data);
    }
  }, [clientId, customerAccount, getMachineId, timeZoneStandardName, ip, logLevel, debugEnabled]);

  function sendLog(logData, value) {
    let zone= localStorage.getItem('zone')?.toLowerCase() || getZoneFromLocalStorage()?.toLowerCase();
    let logServiceUrl = zone === "west" ? `${process.env.REACT_APP_LOGGING_SERVICE_WEST}${value}`:
                                          `${process.env.REACT_APP_LOGGING_SERVICE_EAST}${value}`

    LogsPostService(logServiceUrl, logData)
      .then((res) => {
        
        dispatch({type: "set", uiRequestId: res?.requestId});
      })
      .catch((error) => console.log(error.message, value + ' Type'));
  }
  const contextValue = useMemo(() => {
    return { logger, setLogLevel, logLevel, debugEnabled, time,dateformat,timezone};
  }, [logger, setLogLevel, logLevel, debugEnabled,time,dateformat,timezone]);

  return (
    <LoggerContext.Provider value={contextValue}>
      {children}
    </LoggerContext.Provider>
  );
};

export default LoggerProvider;
